import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

const betterCalendlyURL =
  "https://calendly.com/d/d56-qkn-h6z/better?hide_event_type_details=1&month=2025-02";

const b2bCalendlyURL =
  "https://calendly.com/d/crjs-24h-kcf/better?hide_event_type_details=1&month=2025-02";

const App = () => {
  const calendlyRef = useRef(null);
  const [calendlyParams, setCalendlyParams] = useState({});
  const [stylingParams, setStylingParams] = useState({});
  const [calendlyEventURL, setCalendlyEventURL] = useState(betterCalendlyURL);

  const getParentUrl = async () => {
    debugger;
    await window.parentUrlPromise;
    const parentUrl = new URL(window.parentUrl);
    const parentUrlParams = new URLSearchParams(parentUrl.search);
    const calendlyParams = {
      utmCampaign: parentUrlParams.get("utmCampaign"),
      utmSource: parentUrlParams.get("utmSource"),
      utmTerm: parentUrlParams.get("utmTerm"),
    };
    setCalendlyParams(calendlyParams);

    if (parentUrlParams.get("utmSource") === "KODEM") {
      setCalendlyEventURL(b2bCalendlyURL);
    }
  };

  const getStylingParams = () => {
    const iFrameUrl = new URL(window.location.href);
    const iFrameUrlParams = new URLSearchParams(iFrameUrl.search);
    const stylingParams = {
      width: iFrameUrlParams.get("width"),
      height: iFrameUrlParams.get("height"),
    };
    setStylingParams(stylingParams);
  };

  const onEventScheduled = (e) => {
    console.log("calendlyEventScheduled");
    window.parent.postMessage(
      "calendlyEventScheduled",
      "https://go.allbetter.co.il"
    );
  };

  const onDateAndTimeSelected = (e) => {
    console.log("calendlyDateAndTimeSelected");
    // window.parent.postMessage(
    //   "calendlyDateAndTimeSelected",
    //   "https://go.allbetter.co.il"
    // );

    const iframe = document.getElementsByTagName("iframe")[0];
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    // alert("calendlyDateAndTimeSelected", iframe);
    // console.log(iframe);
    debugger;
    let style = document.createElement("style");
    // style.type = 'text/css';
    style.innerHTML = "data-container=[details] {" + "display:none" + "}";
    iframeDoc.head.appendChild(style);
  };

  useCalendlyEventListener({ onEventScheduled });

  useEffect(() => {
    getStylingParams();
  }, []);

  useEffect(() => {
    getParentUrl();
  }, []);

  const getStyles = () => {
    return { ...stylingParams, overflow: "hidden" };
  };

  const containerStyles = {
    width: addUnits(stylingParams.width, 10),
    height: addUnits(stylingParams.height, 10),
  };

  return (
    <div
      className="calenldy-iframe"
      id="calendly-iframe"
      style={containerStyles}
    >
      <InlineWidget
        ref={calendlyRef}
        url={calendlyEventURL}
        styles={getStyles()}
        pageSettings={{
          backgroundColor: "ffffff",
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          hideGdprBanner: true,
          primaryColor: "00a2ff",
          textColor: "4d5055",
        }}
        utm={{
          utmCampaign: calendlyParams.utmCampaign,
          utmSource: calendlyParams.utmSource,
          utmTerm: calendlyParams.utmTerm,
        }}
      />
    </div>
  );
};
export default App;

function addUnits(value, increment) {
  if (!value) return "100%";

  const match = value.match(/^(\d+)([a-z%]*)$/i);
  if (!match) return value;

  const number = parseInt(match[1], 10);
  const unit = match[2] || "px"; // default to px if no unit is found

  return `${number + increment}${unit}`;
}
